import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { F40Form } from '../../store/interface';
import { states } from '../constants';
import Modal, { Modal2 } from '../SubComponent/Modal';

export const F40FormComponent = (props: { token: string }) => {
  //export const F40Page = () => {

  const [form, setForm] = React.useState(undefined as F40Form | undefined);
  const [loading, setLoading] = React.useState(true);
  const [validated, setValidate] = React.useState(false);
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [removeAttachmentId, setRemoveAttachmentId] = React.useState(
    null as number | null
  );
  const [popupMessage, setPopupMessage] = React.useState(null as string | null);

  const [errorMessage, setErrorMessage] = React.useState(null as string | null);

  React.useEffect(() => {
    _getForm(props.token);
  }, [props.token]);

  React.useEffect(() => {
    if (form) {
      setValidate(_checkFormValidation(form));
    }
  }, [form]);

  const _checkFormValidation = (form: F40Form) => {
    if (form.fullTimeEmployees === 0 || isNaN(form.fullTimeEmployees))
      return false;
    if (form.isDivisionOfCorporation) {
      if (!form.parentCorpAddress1 || !form.parentCorpAddress1.length)
        return false;
      if (!form.parentCorpCity || !form.parentCorpCity.length) return false;
      if (!form.parentCorpStateProvince || !form.parentCorpStateProvince.length)
        return false;
      if (!form.parentCorpZip || !form.parentCorpZip.length) return false;
      if (!form.parentCorpCountry || !form.parentCorpCountry.length)
        return false;
    }
    if (!form.isSelfInsured) {
      if (!form.thirdPartyInsurer || !form.thirdPartyInsurer.length)
        return false;
    }
    if (form.safetyTopics.holdsSiteSafetyMeetings) {
      if (
        !form.safetyTopics.frequencyOfSafetyMeetings ||
        !form.safetyTopics.frequencyOfSafetyMeetings.length
      )
        return false;
    }
    if (form.safetyTopics.conductsSafetyAudits) {
      if (
        !form.safetyTopics.safetyAuditsFrequency ||
        !form.safetyTopics.safetyAuditsFrequency.length
      )
        return false;
    }
    if (form.safetyPerformanceYears.find(x => (x.emr || '').trim() === '')) return false;
    if (form.safetyPerformanceYears.find(x => (x.laborHoursWorked || '').trim() === '')) return false;
    if (form.safetyPerformanceYears.find(x => (x.averageEmployeesOnPayroll || '').trim() === '')) return false;
    if (form.safetyPerformanceYears.find(x => (x.numberOfFatalities || '').trim() === '')) return false;
    if (form.safetyPerformanceYears.find(x => (x.numberOfRecordableCases || '').trim() === '')) return false;
    if (form.safetyPerformanceYears.find(x => (x.numberOfRestrictedCases || '').trim() === '')) return false;
    if (form.safetyPerformanceYears.find(x => (x.numberOfLostTimeCases || '').trim() === '')) return false;
    if (!form.submitterName || !form.submitterName.length) return false;
    if (!form.submitterTitle || !form.submitterTitle.length) return false;
    if (!form.submitterPhone || !form.submitterPhone.length) return false;
    if (!form.submitterEmail || !form.submitterEmail.length) return false;
    if (!form.digitalSignature || !form.digitalSignature.length) return false;
    if (!form.digitalSignatureTitle || !form.digitalSignatureTitle.length)
      return false;
    if (!form.healthSafetyProName || !form.healthSafetyProName.length)
      return false;
    if (!form.healthSafetyProLocation || !form.healthSafetyProLocation.length)
      return false;
    if (!form.healthSafetyProPhone || !form.healthSafetyProPhone.length)
      return false;
    if (form.hasReceivedOSHACitations) {
      if (form.attachments === null || form.attachments.length === 0) {
        return false;
      }
    }

    return true;
  };

  const _getForm = (token: string) => {
    fetch(`api/f40/form?token=${token}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setLoading(false);
        if (resOk) {
          setForm(data);
        } else {
          setErrorMessage(data.message);
        }
      });
  };

  const _onUpdate = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    let value: string | boolean | number = '';
    if (e.currentTarget.type === 'checkbox') {
      value = (e.currentTarget as HTMLInputElement).checked;
    } else if (e.currentTarget.type === 'radio') {
      const dataValue = e.currentTarget.getAttribute('data-value');
      if (dataValue) {
        value = dataValue;
      } else {
        const boolValue = e.currentTarget.getAttribute('data-bool-value');
        value = boolValue === 'false' ? false : true;
      }
    } else if (e.currentTarget.type === 'number') {
      value = parseFloat(e.currentTarget.value);
      if (isNaN(value)) {
        value = 0;
      }
    } else {
      value = e.currentTarget.value;
    }

    const split = e.currentTarget.name.split('.');
    const newForm = { ...form } as F40Form;
    if (split.length === 2) {
      // @ts-ignore
      newForm[split[0]][split[1]] = value;
    } else newForm[split[0]] = value;

    setForm({ ...newForm });
    setUnsavedChanges(true);
  };

  const _onPerformanceUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const i = parseInt(e.currentTarget.getAttribute('data-index'));
    const name = e.currentTarget.name;
    const newForm = { ...form } as F40Form;
    newForm.safetyPerformanceYears[i][name] = e.currentTarget.value;

    if (
      name === 'laborHoursWorked' ||
      name === 'numberOfRecordableCases' ||
      name === 'numberOfRestrictedCases' ||
      name === 'numberOfLostTimeCases'
    ) {
      const year = newForm.safetyPerformanceYears[i];
      if (year.laborHoursWorked && year.numberOfRecordableCases) {
        newForm.safetyPerformanceYears[i].recordableIncidentRate = (
          Math.round(
            100 *
            ((200000 * parseInt(year.numberOfRecordableCases)) /
              parseInt(year.laborHoursWorked))
          ) / 100
        ).toString();
      }
      if (year.laborHoursWorked && year.numberOfRestrictedCases) {
        newForm.safetyPerformanceYears[i].restrictedOnlyIncidentRate = (
          Math.round(
            100 *
            ((200000 * parseInt(year.numberOfRestrictedCases)) /
              parseInt(year.laborHoursWorked))
          ) / 100
        ).toString();
      }
      if (year.laborHoursWorked && year.numberOfRecordableCases) {
        newForm.safetyPerformanceYears[i].lostTimeIncidentRate = (
          Math.round(
            100 *
            ((200000 * parseInt(year.numberOfLostTimeCases)) /
              parseInt(year.laborHoursWorked))
          ) / 100
        ).toString();
      }
    }

    setForm({ ...newForm });
    setUnsavedChanges(true);
  };

  const _removeAttachment = () => {
    fetch(`api/f40/removeattachment?attachmentId=${removeAttachmentId}`, {
      method: 'DELETE',
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk && form) {
          setForm({
            ...form,
            attachments: form.attachments.filter(
              (x) => x.id !== removeAttachmentId
            ),
          });
          setTimeout(() => setRemoveAttachmentId(null), 300);
        }
      });
  };

  const _save = () => {
    const formData = new FormData();
    formData.append('json', JSON.stringify(form));
    const citations = document.getElementById(
      'citation_files'
    ) as HTMLInputElement;

    if (citations && citations.files) {
      Array.from(citations.files).forEach((file, i) => {
        formData.append(`citationFiles[${i}]`, file, file.name);
      });
    }

    fetch(`api/f40/save`, {
      method: 'PUT',
      body: formData,
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setForm(data as F40Form);
          setUnsavedChanges(false);
          setPopupMessage(
            'Your changes have been successfully saved! You can now submit your form, or come back later to continue filling out the form.'
          );
        } else {
          setErrorMessage(data.message);
        }
      });
  };

  const _submit = () => {
    // validate all the required fields
    if (form && validated) {
      fetch(`api/f40/submit?vendorId=${form.vendorDetailId}`, { method: 'PUT' })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            setForm(data as F40Form);
            setUnsavedChanges(false);
            setPopupMessage(
              'Your form has been successfully submitted! You should receive an automated confirmation email shortly.'
            );
          } else {
            setErrorMessage(data.message);
          }
        });
    }
  };

  const errorComponent = errorMessage ? (
    <div className="f40-error-message">
      <h4>{errorMessage}</h4>
    </div>
  ) : (
    []
  );
  const popupMessageComponent = popupMessage ? (
    <Modal2>
      <div className="modal-body">{popupMessage}</div>
      <div className="modal-footer">
        <button
          className="btn btn-sm btn-light"
          style={{ color: 'black' }}
          onClick={() => setPopupMessage(null)}
        >
          Close
        </button>
      </div>
    </Modal2>
  ) : (
    []
  );
  const removeAttachmentComponent = removeAttachmentId ? (
    <Modal2 dialogClass="white">
      <div className="modal-body" style={{ textAlign: 'center' }}>
        Are you sure you want to delete this attachment?
      </div>
      <div className="modal-footer">
        <button className="btn btn-sm btn-danger" onClick={_removeAttachment}>
          Delete
        </button>
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => setRemoveAttachmentId(null)}
        >
          Cancel
        </button>
      </div>
    </Modal2>
  ) : (
    []
  );

  const formComponent = form ? (
    <div className="f40-form">
      <h2 style={{ textAlign: 'center' }}>
        Contractor Safety Qualification Form (CQSF)
      </h2>
      <p>
        <u>
          <b>Please Note: </b> You can save your changes as you work on this
          form to return later.
        </u>
      </p>
      <F40FormSection title="Section 1 - General">
        <F40FormRow>
          <F40FormGroup label="Company Name" width="100%">
            <input className="form-control" disabled value={form.companyName} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Address" width="33%">
            <input className="form-control" disabled value={form.address} />
          </F40FormGroup>
          <F40FormGroup label="City" width="33%">
            <input className="form-control" disabled value={`${form.city}`} />
          </F40FormGroup>
          <F40FormGroup label="State/Province" width="33%">
            <input
              className="form-control"
              disabled
              value={`${form.stateProvince}`}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Zip/Postal Code" width="33%">
            <input className="form-control" disabled value={form.zip} />
          </F40FormGroup>
          <F40FormGroup label="Country" width="33%">
            <input
              className="form-control"
              disabled
              value={form.country || ''}
            />
          </F40FormGroup>
          <F40FormGroup label="Phone" width="33%">
            <input className="form-control" disabled value={form.phone} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>Indicate how many employees you employee (appx)</b>
          </p>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Full-Time*" width="33%">
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                  //!unsavedChanges &&
                  (form.fullTimeEmployees === 0 ||
                    isNaN(form.fullTimeEmployees))
                  ? 'is-invalid'
                  : ''
                  } `}
                type="number"
                name="fullTimeEmployees"
                value={form.fullTimeEmployees}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Full-Time number.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
          <F40FormGroup label="Part-Time" width="33%">
            <input
              className="form-control"
              type="number"
              name="partTimeEmployees"
              value={form.partTimeEmployees}
              onChange={_onUpdate}
            />
          </F40FormGroup>
          <F40FormGroup label="Seasonal" width="33%">
            <input
              className="form-control"
              type="number"
              name="seasonalEmployees"
              value={form.seasonalEmployees}
              onChange={_onUpdate}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%" noFlex={true}>
            <p>
              <b>
                Describe the nature of business your organization typically
                performs*
              </b>
            </p>
            <textarea
              className={`form-control ${!validated &&
                //!unsavedChanges &&
                (!form.natureOfBusiness || !form.natureOfBusiness.length)
                ? 'is-invalid'
                : ''
                } `}
              rows={4}
              name="natureOfBusiness"
              value={form.natureOfBusiness}
              onChange={_onUpdate}
            />
            <div className="invalid-feedback">
              Please provide description above.
            </div>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup noFlex={true} width={'33%'}>
            <p>
              <b>Are you a division of a corporation?</b>
            </p>
            <input
              type="radio"
              name="isDivisionOfCorporation"
              checked={form.isDivisionOfCorporation}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="isDivisionOfCorporation"
              checked={!form.isDivisionOfCorporation}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        {form.isDivisionOfCorporation && (
          <>
            <F40FormRow>
              <p>
                <b>Parent Corporation Address:</b>
              </p>
            </F40FormRow>
            <F40FormRow>
              <F40FormGroup label="Address*" width="33%">
                <F40FormValidationGroup>
                  <input
                    className={`form-control ${!validated &&
                      ////!unsavedChanges &&
                      (!form.parentCorpAddress1 ||
                        !form.parentCorpAddress1.length)
                      ? 'is-invalid'
                      : ''
                      } `}
                    name="parentCorpAddress1"
                    value={form.parentCorpAddress1}
                    onChange={_onUpdate}
                  />
                  <div className="invalid-feedback">
                    Please provide a valid Address.
                  </div>
                </F40FormValidationGroup>
              </F40FormGroup>
              <F40FormGroup label="City*" width="33%">
                <F40FormValidationGroup>
                  <input
                    className={`form-control ${!validated &&
                      //!unsavedChanges &&
                      (!form.parentCorpCity || !form.parentCorpCity.length)
                      ? 'is-invalid'
                      : ''
                      } `}
                    name="parentCorpCity"
                    value={`${form.parentCorpCity}`}
                    onChange={_onUpdate}
                  />
                  <div className="invalid-feedback">
                    Please provide a valid City.
                  </div>
                </F40FormValidationGroup>
              </F40FormGroup>
              <F40FormGroup label="State*" width="33%">
                <F40FormValidationGroup>
                  <select
                    className={`form-select ${!validated &&
                      //!unsavedChanges &&
                      (!form.parentCorpStateProvince ||
                        !form.parentCorpStateProvince.length)
                      ? 'is-invalid'
                      : ''
                      } `}
                    name="parentCorpStateProvince"
                    value={`${form.parentCorpStateProvince}`}
                    onChange={_onUpdate}
                  >
                    {states.map((x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    Please provide a valid State.
                  </div>
                </F40FormValidationGroup>
              </F40FormGroup>
            </F40FormRow>
            <F40FormRow>
              <F40FormGroup label="Zip/Postal Code*" width="33%">
                <F40FormValidationGroup>
                  <input
                    className={`form-control ${!validated &&
                      //!unsavedChanges &&
                      (!form.parentCorpZip || !form.parentCorpZip.length)
                      ? 'is-invalid'
                      : ''
                      } `}
                    name="parentCorpZip"
                    value={form.parentCorpZip}
                    onChange={_onUpdate}
                  />
                  <div className="invalid-feedback">
                    Please provide a valid Zip/Postal Code.
                  </div>
                </F40FormValidationGroup>
              </F40FormGroup>
              <F40FormGroup label="Country*" width="33%">
                <F40FormValidationGroup>
                  <input
                    className={`form-control ${!validated &&
                      //!unsavedChanges &&
                      (!form.parentCorpCountry ||
                        !form.parentCorpCountry.length)
                      ? 'is-invalid'
                      : ''
                      } `}
                    name="parentCorpCountry"
                    value={form.parentCorpCountry}
                    onChange={_onUpdate}
                  />
                  <div className="invalid-feedback">
                    Please provide a valid Country.
                  </div>
                </F40FormValidationGroup>
              </F40FormGroup>
            </F40FormRow>
          </>
        )}
        <F40FormRow>
          <F40FormGroup noFlex={true} width={'33%'}>
            <p>
              <b>Are you self-insured for Worker’s Compensation insurance?</b>
            </p>
            <input
              type="radio"
              name="isSelfInsured"
              checked={form.isSelfInsured}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="isSelfInsured"
              checked={!form.isSelfInsured}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
          {!form.isSelfInsured && (
            <F40FormGroup
              width="66%"
              label="Name of your third-party insurance organization*"
            >
              <F40FormValidationGroup>
                <input
                  className={`form-control ${!validated &&
                    //!unsavedChanges &&
                    (!form.thirdPartyInsurer || !form.thirdPartyInsurer.length)
                    ? 'is-invalid'
                    : ''
                    } `}
                  name="thirdPartyInsurer"
                  value={form.thirdPartyInsurer}
                  onChange={_onUpdate}
                />
                <div className="invalid-feedback">
                  Please provide a valid Insurer.
                </div>
              </F40FormValidationGroup>
            </F40FormGroup>
          )}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%" noFlex>
            <p>
              <b>Does your company use subcontractors?</b>
            </p>
            <input
              type="radio"
              name="usesSubcontractors"
              checked={form.usesSubcontractors}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="usesSubcontractors"
              checked={!form.usesSubcontractors}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>Form completed by:</b>
          </p>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="33%" label="Name*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                  //!unsavedChanges &&
                  (!form.submitterName || !form.submitterName.length)
                  ? 'is-invalid'
                  : ''
                  } `}
                name="submitterName"
                value={form.submitterName}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Name.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
          <F40FormGroup width="33%" label="Title*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                  //!unsavedChanges &&
                  (!form.submitterTitle || !form.submitterTitle.length)
                  ? 'is-invalid'
                  : ''
                  } `}
                name="submitterTitle"
                value={form.submitterTitle}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Title.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="33%" label="Email*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                  //!unsavedChanges &&
                  (!form.submitterEmail || !form.submitterEmail.length)
                  ? 'is-invalid'
                  : ''
                  } `}
                name="submitterEmail"
                value={form.submitterEmail}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Email.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
          <F40FormGroup width="33%" label="Phone*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                  //!unsavedChanges &&
                  (!form.submitterPhone || !form.submitterPhone.length)
                  ? 'is-invalid'
                  : ''
                  } `}
                name="submitterPhone"
                value={form.submitterPhone}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Email.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
      </F40FormSection>
      <F40FormSection title="Section 2 - Health & Safety Information">
        <F40FormRow>
          <F40FormGroup noFlex width="100%">
            <p>
              <b>
                Do you have a dedicated fulltime health & safety professional
                within your company?*
              </b>
            </p>
            <input
              type="radio"
              name="hasHealthSafetyProfessional"
              checked={form.hasHealthSafetyProfessional}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="hasHealthSafetyProfessional"
              checked={!form.hasHealthSafetyProfessional}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>
              Who is responsible for health & safety within your organization?
            </b>
          </p>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="33%" label="Name/Title*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                  //!unsavedChanges &&
                  (!form.healthSafetyProName ||
                    !form.healthSafetyProName.length)
                  ? 'is-invalid'
                  : ''
                  } `}
                name="healthSafetyProName"
                value={form.healthSafetyProName}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Name or Title.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
          <F40FormGroup width="33%" label="Phone*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                  //!unsavedChanges &&
                  (!form.healthSafetyProPhone ||
                    !form.healthSafetyProPhone.length)
                  ? 'is-invalid'
                  : ''
                  } `}
                name="healthSafetyProPhone"
                value={form.healthSafetyProPhone}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Phone.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
          <F40FormGroup width="33%" label="Location*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                  //!unsavedChanges &&
                  (!form.healthSafetyProLocation ||
                    !form.healthSafetyProLocation.length)
                  ? 'is-invalid'
                  : ''
                  } `}
                name="healthSafetyProLocation"
                value={form.healthSafetyProLocation}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Location.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
      </F40FormSection>
      <F40FormSection title="Section 3 – Health & Safety Programs and Procedures">
        <F40FormRow>
          <F40FormGroup width="50%">
            <b>Does your company have a written health & safety program?*</b>
            <input
              type="radio"
              name="hasSafetyProgram"
              checked={form.hasSafetyProgram}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="hasSafetyProgram"
              checked={!form.hasSafetyProgram}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%">
            <b>
              Does your written Safety program cover all types of work
              activities which you will perform?*
            </b>
            <input
              type="radio"
              name="doesSafetyProgramCoverAllActivities"
              checked={form.doesSafetyProgramCoverAllActivities}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="doesSafetyProgramCoverAllActivities"
              checked={!form.doesSafetyProgramCoverAllActivities}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>Does your SAFETY program address the following elements:</b>
          </p>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b>Management commitment and expectations*</b>
            <input
              type="radio"
              name="coveredMgmtExpectations"
              checked={form.coveredMgmtExpectations}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="coveredMgmtExpectations"
              checked={!form.coveredMgmtExpectations}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b>
              Accountabilities and responsibilities for managers, supervisors,
              and employees*
            </b>
            <input
              type="radio"
              name="coveredAccountabilities"
              checked={form.coveredAccountabilities}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="coveredAccountabilities"
              checked={!form.coveredAccountabilities}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b>Hazard recognition and control*</b>
            <input
              type="radio"
              name="coveredHazardControl"
              checked={form.coveredHazardControl}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="coveredHazardControl"
              checked={!form.coveredHazardControl}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b>Workplace Analysis*</b>
            <input
              type="radio"
              name="coveredWorkplaceAnalysis"
              checked={form.coveredWorkplaceAnalysis}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="coveredWorkplaceAnalysis"
              checked={!form.coveredWorkplaceAnalysis}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b>Safety Training*</b>
            <input
              type="radio"
              name="coveredSafetyTraining"
              checked={form.coveredSafetyTraining}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="coveredSafetyTraining"
              checked={!form.coveredSafetyTraining}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b>Incident reporting and investigation, including near misses*</b>
            <input
              type="radio"
              name="coveredIncidentReporting"
              checked={form.coveredIncidentReporting}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="coveredIncidentReporting"
              checked={!form.coveredIncidentReporting}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>
              Does your written S&H Program contain the following
              programs/topics? Identify those topics that your work will fall
              under.
            </b>
          </p>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Confined Space*</b>
            <input
              type="radio"
              name="safetyTopics.confinedSpaces"
              checked={form.safetyTopics.confinedSpaces === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.confinedSpaces"
              checked={form.safetyTopics.confinedSpaces === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.confinedSpaces"
              checked={form.safetyTopics.confinedSpaces === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Material Handling*</b>
            <input
              type="radio"
              name="safetyTopics.materialHandling"
              checked={form.safetyTopics.materialHandling === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.materialHandling"
              checked={form.safetyTopics.materialHandling === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.materialHandling"
              checked={form.safetyTopics.materialHandling === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Control of Hazardous Energy LOTO*</b>
            <input
              type="radio"
              name="safetyTopics.controlHazardEnergy"
              checked={form.safetyTopics.controlHazardEnergy === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.controlHazardEnergy"
              checked={form.safetyTopics.controlHazardEnergy === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.controlHazardEnergy"
              checked={form.safetyTopics.controlHazardEnergy === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Scaffolding*</b>
            <input
              type="radio"
              name="safetyTopics.scaffolding"
              checked={form.safetyTopics.scaffolding === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.scaffolding"
              checked={form.safetyTopics.scaffolding === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.scaffolding"
              checked={form.safetyTopics.scaffolding === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Fire Prevention & Protection*</b>
            <input
              type="radio"
              name="safetyTopics.firePrevention"
              checked={form.safetyTopics.firePrevention === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.firePrevention"
              checked={form.safetyTopics.firePrevention === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.firePrevention"
              checked={form.safetyTopics.firePrevention === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Fall Protection*</b>
            <input
              type="radio"
              name="safetyTopics.fallProtection"
              checked={form.safetyTopics.fallProtection === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.fallProtection"
              checked={form.safetyTopics.fallProtection === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.fallProtection"
              checked={form.safetyTopics.fallProtection === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Personal Protective Equipment*</b>
            <input
              type="radio"
              name="safetyTopics.ppe"
              checked={form.safetyTopics.ppe === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.ppe"
              checked={form.safetyTopics.ppe === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.ppe"
              checked={form.safetyTopics.ppe === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Rigging / Cranes*</b>
            <input
              type="radio"
              name="safetyTopics.riggingAndCranes"
              checked={form.safetyTopics.riggingAndCranes === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.riggingAndCranes"
              checked={form.safetyTopics.riggingAndCranes === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.riggingAndCranes"
              checked={form.safetyTopics.riggingAndCranes === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Hot Work / Welding Safety*</b>
            <input
              type="radio"
              name="safetyTopics.weldingSafety"
              checked={form.safetyTopics.weldingSafety === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.weldingSafety"
              checked={form.safetyTopics.weldingSafety === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.weldingSafety"
              checked={form.safetyTopics.weldingSafety === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Excavation & Trenching*</b>
            <input
              type="radio"
              name="safetyTopics.excavationAndTrenches"
              checked={form.safetyTopics.excavationAndTrenches === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.excavationAndTrenches"
              checked={form.safetyTopics.excavationAndTrenches === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.excavationAndTrenches"
              checked={form.safetyTopics.excavationAndTrenches === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Hand & Powered Tools*</b>
            <input
              type="radio"
              name="safetyTopics.handAndPoweredTools"
              checked={form.safetyTopics.handAndPoweredTools === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.handAndPoweredTools"
              checked={form.safetyTopics.handAndPoweredTools === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.handAndPoweredTools"
              checked={form.safetyTopics.handAndPoweredTools === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Emergency Action Plans*</b>
            <input
              type="radio"
              name="safetyTopics.emergencyActionPlans"
              checked={form.safetyTopics.emergencyActionPlans === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.emergencyActionPlans"
              checked={form.safetyTopics.emergencyActionPlans === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.emergencyActionPlans"
              checked={form.safetyTopics.emergencyActionPlans === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%">
            <b style={{ width: '250px ' }}>Respiratory Protection*</b>
            <input
              type="radio"
              name="safetyTopics.respiratoryProtection"
              checked={form.safetyTopics.respiratoryProtection === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.respiratoryProtection"
              checked={form.safetyTopics.respiratoryProtection === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.respiratoryProtection"
              checked={form.safetyTopics.respiratoryProtection === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Do you have employees trained in First Aid/AED/CPR?*{' '}
            </b>
            <input
              type="radio"
              name="safetyTopics.hasFirstAidTrainedEmployees"
              checked={form.safetyTopics.hasFirstAidTrainedEmployees}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.hasFirstAidTrainedEmployees"
              checked={!form.safetyTopics.hasFirstAidTrainedEmployees}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Do you have a drug and alcohol testing policy?*{' '}
            </b>
            <input
              type="radio"
              name="safetyTopics.hasDrugTestingPolicy"
              checked={form.safetyTopics.hasDrugTestingPolicy}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.hasDrugTestingPolicy"
              checked={!form.safetyTopics.hasDrugTestingPolicy}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Do you employ persons with a Commercial Driver’s License (CDL)?*{' '}
            </b>
            <input
              type="radio"
              name="safetyTopics.hasCDLEmployees"
              checked={form.safetyTopics.hasCDLEmployees}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.hasCDLEmployees"
              checked={!form.safetyTopics.hasCDLEmployees}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="40%">
            <b style={{ width: '400px ' }}>
              Do you hold site safety meetings?*{' '}
            </b>
            <input
              type="radio"
              name="safetyTopics.holdsSiteSafetyMeetings"
              checked={form.safetyTopics.holdsSiteSafetyMeetings}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.holdsSiteSafetyMeetings"
              checked={!form.safetyTopics.holdsSiteSafetyMeetings}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
          {form.safetyTopics.holdsSiteSafetyMeetings && (
            <F40FormGroup
              width="60%"
              label="Frequency of the meetings*"
              labelWidth="300px"
            >
              <F40FormValidationGroup>
                <input
                  className={`form-control ${!validated &&
                    //!unsavedChanges &&
                    (!form.safetyTopics.frequencyOfSafetyMeetings ||
                      !form.safetyTopics.frequencyOfSafetyMeetings.length)
                    ? 'is-invalid'
                    : ''
                    } `}
                  name="safetyTopics.frequencyOfSafetyMeetings"
                  value={form.safetyTopics.frequencyOfSafetyMeetings}
                  onChange={_onUpdate}
                />
                <div className="invalid-feedback">
                  Please provide a valid frequency.
                </div>
              </F40FormValidationGroup>
            </F40FormGroup>
          )}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="40%">
            <b style={{ width: '400px ' }}>
              Do you conduct health & safety inspections/audits?*
            </b>
            <input
              type="radio"
              name="safetyTopics.conductsSafetyAudits"
              checked={form.safetyTopics.conductsSafetyAudits}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.conductsSafetyAudits"
              checked={!form.safetyTopics.conductsSafetyAudits}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
          {form.safetyTopics.conductsSafetyAudits && (
            <F40FormGroup
              width="60%"
              label="Frequency of the meetings*"
              labelWidth="300px"
            >
              <F40FormValidationGroup>
                <input
                  className={`form-control ${!validated &&
                    //!unsavedChanges &&
                    (!form.safetyTopics.safetyAuditsFrequency ||
                      !form.safetyTopics.safetyAuditsFrequency.length)
                    ? 'is-invalid'
                    : ''
                    } `}
                  name="safetyTopics.safetyAuditsFrequency"
                  value={form.safetyTopics.safetyAuditsFrequency}
                  onChange={_onUpdate}
                />
                <div className="invalid-feedback">
                  Please provide a valid frequency.
                </div>
              </F40FormValidationGroup>
            </F40FormGroup>
          )}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Do you have a disciplinary action process for addressing employee
              health & safety performance?*
            </b>
            <input
              type="radio"
              name="safetyTopics.hasDisciplinaryProcess"
              checked={form.safetyTopics.hasDisciplinaryProcess}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.hasDisciplinaryProcess"
              checked={!form.safetyTopics.hasDisciplinaryProcess}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '800px ' }}>
              If you use subcontractors, do you use health & safety performance
              criteria in the selection of subcontractors?*
            </b>
            <input
              type="radio"
              name="safetyTopics.usesSafetyCriteriaForSubs"
              checked={form.safetyTopics.usesSafetyCriteriaForSubs === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.usesSafetyCriteriaForSubs"
              checked={form.safetyTopics.usesSafetyCriteriaForSubs === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="safetyTopics.usesSafetyCriteriaForSubs"
              checked={form.safetyTopics.usesSafetyCriteriaForSubs === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
        </F40FormRow>
      </F40FormSection>
      <F40FormSection title="Section 4 - S&H Training">
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Do you perform New Employee S&H Orientation Training?*
            </b>
            <input
              type="radio"
              name="hasSHOrientationTraining"
              checked={form.hasSHOrientationTraining}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="hasSHOrientationTraining"
              checked={!form.hasSHOrientationTraining}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Do you provide the necessary and required S&H training for your
              employees?*
            </b>
            <input
              type="radio"
              name="hasSHTraining"
              checked={form.hasSHTraining}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="hasSHTraining"
              checked={!form.hasSHTraining}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Are employees test for this comprehension of the training
              materials?*
            </b>
            <input
              type="radio"
              name="hasSHTrainingTests"
              checked={form.hasSHTrainingTests}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="hasSHTrainingTests"
              checked={!form.hasSHTrainingTests}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>Is all S&H Training documented?*</b>
            <input
              type="radio"
              name="hasSHTrainingDocumentation"
              checked={form.hasSHTrainingDocumentation}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="hasSHTrainingDocumentation"
              checked={!form.hasSHTrainingDocumentation}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Are employees certified/qualified to operate all machinery and
              equipment they are asked to?*
            </b>
            <input
              type="radio"
              name="hasCertsForAllMachineryOperators"
              checked={form.hasCertsForAllMachineryOperators}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="hasCertsForAllMachineryOperators"
              checked={!form.hasCertsForAllMachineryOperators}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
      </F40FormSection>
      <F40FormSection title="Section 5 - Job Safety">
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Are job observations, such as job safety analysis (JSA)
              conducted?*
            </b>
            <input
              type="radio"
              name="hasJobSafetyAnalysis"
              checked={form.hasJobSafetyAnalysis}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="hasJobSafetyAnalysis"
              checked={!form.hasJobSafetyAnalysis}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Are procedures for critical jobs written and reviewed with the
              employees?*
            </b>
            <input
              type="radio"
              name="proceduresWrittenAndReviewed"
              checked={form.proceduresWrittenAndReviewed === 'Yes'}
              data-value="Yes"
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="proceduresWrittenAndReviewed"
              checked={form.proceduresWrittenAndReviewed === 'No'}
              data-value="No"
              onChange={_onUpdate}
            />
            No &nbsp; &nbsp;
            <input
              type="radio"
              name="proceduresWrittenAndReviewed"
              checked={form.proceduresWrittenAndReviewed === 'N/A'}
              data-value="N/A"
              onChange={_onUpdate}
            />
            N/A
          </F40FormGroup>
        </F40FormRow>
      </F40FormSection>
      <F40FormSection title="Section 6 - Health & Safety Performance">
        <F40FormRow>
          <p>
            <b>
              List your company’s Workers’ Compensation Experience Modification
              Rate (EMR) for the three (3) most recent years (enter N/A if you have none):
            </b>
          </p>
        </F40FormRow>
        <F40FormRow>
          {form.safetyPerformanceYears.map((x, i) => (
            <F40FormGroup key={i} width="33%" label={`${x.year} EMR*`}>
              <input
                className={`form-control ${!validated && (x.emr || '').trim() === '' ? 'is-invalid' : ''} `}
                name="emr"
                value={x.emr}
                data-index={i}
                onChange={_onPerformanceUpdate}
              />
            </F40FormGroup>
          ))}
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>
              Use your OSHA 300 log (or equivalent) to record the number of
              injuries and illnesses for the last three (3) years:
            </b>
          </p>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="30%" label="Year" />
          {form.safetyPerformanceYears.map((x, i) => (
            <F40FormGroup key={i} width="20%" label={`${x.year}`} />
          ))}
        </F40FormRow>
        <F40FormRow style={{ position: 'relative' }}>
          <F40FormGroup
            width="30%"
            label="Number of Labor Hours Worked*"
            labelWidth="400px"
          />
          <small style={{ position: 'absolute', top: '2em' }}>Enter N/A if you don't have hours</small>
          {form.safetyPerformanceYears.map((x, i) => (<>
            <F40FormGroup key={i} width="20%">
              <input
                className={`form-control ${!validated && (x.laborHoursWorked || '').trim() === '' ? 'is-invalid' : ''} `}
                name="laborHoursWorked"
                value={x.laborHoursWorked}
                data-index={i}
                onChange={_onPerformanceUpdate}
              />
            </F40FormGroup>
            <div className="invalid-feedback">
              Please enter hours. Enter N/A if you have none.
            </div>
          </>
          ))}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="30%"
            label="Average Number of Employees on Your Payroll*"
            labelWidth="400px"
          />
          {form.safetyPerformanceYears.map((x, i) => (<>
            <F40FormGroup key={i} width="20%">
              <input
                className={`form-control ${!validated && (x.averageEmployeesOnPayroll || '').trim() === '' ? 'is-invalid' : ''} `}
                name="averageEmployeesOnPayroll"
                type="number"
                value={x.averageEmployeesOnPayroll}
                data-index={i}
                onChange={_onPerformanceUpdate}
              />
            </F40FormGroup>
            <div className="invalid-feedback">
              Please enter hours. Enter N/A if you have none.
            </div>
            </>
          ))}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="30%"
            label="Number of Fatalities*"
            labelWidth="400px"
          />
          {form.safetyPerformanceYears.map((x, i) => (<>
            <F40FormGroup key={i} width="20%">
              <input
                className={`form-control ${!validated && (x.numberOfFatalities || '').trim() === '' ? 'is-invalid' : ''} `}
                name="numberOfFatalities"
                type="number"
                value={x.numberOfFatalities}
                data-index={i}
                onChange={_onPerformanceUpdate}
              />
            </F40FormGroup>
            <div className="invalid-feedback">
              Please enter hours. Enter N/A if you have none.
            </div>
            </>
          ))}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="30%"
            label="Number of OSHA Recordable Cases*"
            labelWidth="400px"
          />
          {form.safetyPerformanceYears.map((x, i) => (<>
            <F40FormGroup key={i} width="20%">
              <input
                className={`form-control ${!validated && (x.numberOfRecordableCases || '').trim() === '' ? 'is-invalid' : ''} `}
                name="numberOfRecordableCases"
                type="number"
                value={x.numberOfRecordableCases}
                data-index={i}
                onChange={_onPerformanceUpdate}
              />
            </F40FormGroup>
            <div className="invalid-feedback">
              Please enter hours. Enter N/A if you have none.
            </div>
            </>
          ))}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="30%"
            label="Number of OSHA Restricted Only Cases*"
            labelWidth="400px"
          />
          {form.safetyPerformanceYears.map((x, i) => (<>
            <F40FormGroup key={i} width="20%">
              <input
                className={`form-control ${!validated && (x.numberOfRestrictedCases || '').trim() === '' ? 'is-invalid' : ''} `}
                name="numberOfRestrictedCases"
                type="number"
                value={x.numberOfRestrictedCases}
                data-index={i}
                onChange={_onPerformanceUpdate}
              />
            </F40FormGroup>
            <div className="invalid-feedback">
              Please enter hours. Enter N/A if you have none.
            </div>
            </>
          ))}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="30%"
            label="Number of OSHA Lost Time Cases*"
            labelWidth="400px"
          />
          {form.safetyPerformanceYears.map((x, i) => (<>
            <F40FormGroup key={i} width="20%">
              <input
                className={`form-control ${!validated && (x.numberOfLostTimeCases || '').trim() === '' ? 'is-invalid' : ''} `}
                name="numberOfLostTimeCases"
                type="number"
                value={x.numberOfLostTimeCases}
                data-index={i}
                onChange={_onPerformanceUpdate}
              />
            </F40FormGroup>
            <div className="invalid-feedback">
              Please enter hours. Enter N/A if you have none.
            </div>
            </>
          ))}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="30%"
            label="OSHA Recordable Incident Rate"
            labelWidth="400px"
          />
          {form.safetyPerformanceYears.map((x, i) => (
            <F40FormGroup key={i} width="20%">
              <input
                className="form-control"
                name="recordableIncidentRate"
                type="number"
                value={x.recordableIncidentRate}
                data-index={i}
                disabled
              />
            </F40FormGroup>
          ))}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="30%"
            label="OSHA Restricted Only Incident Rate"
            labelWidth="400px"
          />
          {form.safetyPerformanceYears.map((x, i) => (
            <F40FormGroup key={i} width="20%">
              <input
                className="form-control"
                name="restrictedOnlyIncidentRate"
                type="number"
                value={x.restrictedOnlyIncidentRate}
                data-index={i}
                disabled
              />
            </F40FormGroup>
          ))}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="30%"
            label="OSHA Lost Time Incident Rate"
            labelWidth="400px"
          />
          {form.safetyPerformanceYears.map((x, i) => (
            <F40FormGroup key={i} width="20%">
              <input
                className="form-control"
                name="lostTimeIncidentRate"
                type="number"
                value={x.lostTimeIncidentRate}
                data-index={i}
                disabled
              />
            </F40FormGroup>
          ))}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '700px ' }}>
              Has your company received any OSHA citations within the last five
              (5) years?
            </b>
            <input
              type="radio"
              name="hasReceivedOSHACitations"
              checked={form.hasReceivedOSHACitations}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="hasReceivedOSHACitations"
              checked={!form.hasReceivedOSHACitations}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        {form.hasReceivedOSHACitations && (
          <F40FormRow>
            <F40FormGroup width="100%">
              <b style={{ width: '700px ', color: 'red' }}>
                Please attach any citations along with your responses or
                corrective actions*
              </b>
              <input
                type="file"
                multiple
                id="citation_files"
                name="citation_files"
                accept=".pdf"
                onChange={() => setUnsavedChanges(true)}
              />
            </F40FormGroup>
          </F40FormRow>
        )}
        <F40FormRow>
          {form.attachments.map((x) => {
            return (
              <p key={x.id}>
                {x.fileName} &nbsp;{' '}
                <i
                  className="bi bi-trash"
                  onClick={() => setRemoveAttachmentId(x.id)}
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  title="Remove attachment"
                ></i>
              </p>
            );
          })}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%" label="Signature*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                  //!unsavedChanges &&
                  (!form.digitalSignature || !form.digitalSignature.length)
                  ? 'is-invalid'
                  : ''
                  } `}
                name="digitalSignature"
                value={form.digitalSignature}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Signature.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
          <F40FormGroup width="50%" label="Title*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                  //!unsavedChanges &&
                  (!form.digitalSignatureTitle ||
                    !form.digitalSignatureTitle.length)
                  ? 'is-invalid'
                  : ''
                  } `}
                name="digitalSignatureTitle"
                value={form.digitalSignatureTitle}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Signature Title.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>
              The Contractor/Subcontractor agrees to abide by all CoreStates
              Health & Safety Requirements. Failure to adhere to CoreStates
              Health & Safety Requirements may result in suspension or removal
              from CoreStates job sites and/or termination of the Contract.
            </b>
          </p>
        </F40FormRow>
      </F40FormSection>
      {!form.isSubmitted && (
        <div>
          <F40FormGroup width="100%" noFlex>
            {unsavedChanges && (
              <button className="btn btn-lg btn-light-blue" onClick={_save}>
                Save
              </button>
            )}
            {!unsavedChanges &&
              !validated && (
                <>
                  <p style={{ marginBottom: '5px' }}>
                    <b style={{ color: 'red' }}>
                      Please fill out all required fields (marked with a *) above
                    </b>
                  </p>
                </>
              )}
            {!unsavedChanges && (
                <button
                  className="btn btn-lg btn-dark-blue"
                  onClick={_submit}
                  disabled={!validated}
                >
                  Submit
                </button>
              )}
          </F40FormGroup>
        </div>
      )}
    </div>
  ) : (
    []
  );

  // @ts-ignore
  if (loading) {
    return (
      <div className="main-item">
        <div
          className="animated-background"
          style={{ height: '200px', marginTop: '10px' }}
        >
          <div className="background-masker"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="f40-page">
      {errorComponent}
      {popupMessageComponent}
      {removeAttachmentComponent}
      {formComponent}
    </div>
  );
};

export const F40FormSection = ({
  children,
  title,
}: React.PropsWithChildren<any>) => (
  <div className="f40-form-section">
    <div className="section-header">
      <h3>{title}</h3>
    </div>
    <div className="section-body">{children}</div>
  </div>
);

export const F40FormRow = ({
  children,
  title,
  style,
}: React.PropsWithChildren<any>) => (
  <div className="f40-form-row" style={style ?? {}}>{children}</div>
);

export const F40FormValidationGroup = ({
  children,
}: React.PropsWithChildren<any>) => (
  <div
    className="input-group input-group-sm"
    style={{ width: 'calc(100% - 180px)', flexWrap: 'wrap' }}
  >
    {children}
  </div>
);

export const F40FormGroup = ({
  children,
  label,
  width,
  noFlex,
  labelWidth,
  style,
}: React.PropsWithChildren<{
  label?: string | undefined;
  width: string;
  noFlex?: boolean;
  labelWidth?: string;
  style?: any;
}>) => (
  <div
    className={`f40-form-group form-group input-group input-group-sm ${noFlex ? 'no-flex' : ''}`}
    style={
      style
        ? { ...style, width: width }
        : { display: noFlex ? 'inline-block' : 'inline-flex', width: width }
    }
  >
    {label && (
      <b className="form-label" style={labelWidth ? { width: labelWidth } : {}}>
        {label}
      </b>
    )}

    {children}
  </div>
);
