import { Action, Reducer } from 'redux';
//import { fetch, addTask } from 'domain-task';
import { AppThunkAction } from '.';
import { Trade, SubmitForm, SubFormVM } from './interface';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SubFormState {
  trade: Trade[];
  selectedTrade: Trade[];
  unselectedTrade: string[];
  message: boolean;
  selectedState: string[];
  success: boolean;
  subForm: SubFormVM | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface ReceiveTradeList {
  type: 'RECEIVE_TRADELIST';
  trade: Trade[];
}

interface ReceiveSelectedTradeList {
  type: 'RECEIVE_SELECTEDTRADE';
  selectedTrade: Trade[];
}

interface ReceiveRemovedTradeList {
  type: 'RECEIVE_REMOVEDTRADE';
  trade: Trade[];
}

interface SubmitFormAction {
  type: 'SUBMIT_SUBFORM';
  submitForm: SubmitForm;
}

interface FormSubmittedAction {
  type: 'FORM_SUBMITTED';
}

interface MessageAction {
  type: 'MESSAGE';
  message: boolean;
}

interface SelectedStateAction {
  type: 'RECEIVE_SELECTED_STATE';
  selectedState: string[];
}

interface SubmittedDisplayAction {
  type: 'SUBMITTED_NEWPAGE';
  success: boolean;
}

interface ReceiveFormAction {
  type: 'RECEIVE_FORM';
  form: SubFormVM;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | ReceiveTradeList
  | ReceiveSelectedTradeList
  | ReceiveRemovedTradeList
  | SubmitFormAction
  | FormSubmittedAction
  | MessageAction
  | SelectedStateAction
  | SubmittedDisplayAction
  | ReceiveFormAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  getTradeList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    let fetchTask = fetch(`api/SubForm/TradeDropDown`, {
      credentials: 'same-origin',
    })
      .then((res) => res.json() as Promise<Trade[]>)
      .then((data) => {
        dispatch({
          type: 'RECEIVE_TRADELIST',
          trade: data as Trade[],
        });
      })
      .catch((err) => console.log(err));

    //addTask(fetchTask);
  },

  selectedTradeList:
    (selected: Trade[]): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const prevSelectState = getState().subForm.selectedTrade.concat(
        ...selected
      );

      dispatch({
        type: 'RECEIVE_SELECTEDTRADE',
        selectedTrade: prevSelectState,
      });
      getState().subForm.trade.filter((x) => {
        const a = selected.map((m) => {
          if (m.trade1 == x.trade1) {
            x.trade1 = '';
          }
        });
        return a;
      });
    },
  // Service Areas - saving selected states
  selectedStatesList:
    (selected: string[]): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({
        type: 'RECEIVE_SELECTED_STATE',
        selectedState: selected,
      });
      console.log(getState().subForm.selectedState);
    },

  removeSelectedTradeList:
    (removeList: Trade[]): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const removeSelectedState = getState().subForm.trade.filter((x) => {
        const a = removeList.map((m) => {
          if (x.tradeId == m.tradeId) {
            x.trade1 = m.trade1;
          }
        });
        return a;
      });

      getState().subForm.selectedTrade.filter((m) => {
        removeList.map((x) => {
          if (m.trade1 == x.trade1) {
            m.trade1 = '';
          }
        });
      });

      dispatch({
        type: 'RECEIVE_REMOVEDTRADE',
        trade: removeSelectedState,
      });
    },

  submitForm:
    (submitForm: SubmitForm, isUpdate: boolean): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      console.log('Made it to redux method');
      const selectedTrades = getState().subForm.selectedTrade.filter(
        (x) => x.trade1 != ''
      );

      let f = new FormData();

      let json = JSON.stringify({
        vendorName: submitForm.vendorName,
        state: submitForm.state,
        city: submitForm.city,
        billingAddress: submitForm.billingAddress,
        primaryZip: submitForm.zip,

        physicalAddress: submitForm.p_Address,
        physicalCity: submitForm.p_city,
        physicalState: submitForm.p_state,
        physicalZip: submitForm.p_zip,
        physicalCountry: submitForm.p_country,

        distributedEnergyMarket: submitForm.distributedEnergyMarket,
        evMarket: submitForm.evMarket,
        retailMarket: submitForm.retailMarket,
        evitpCertified: submitForm.evitpCertified,

        contactName: submitForm.contactName,
        contactEmail: submitForm.contactEmail,
        contactTitle: submitForm.contactTitle,
        businessPhone: submitForm.businessPhone,
        altPhone: submitForm.alternativePhone,
        referral: submitForm.referral,
        notes: submitForm.notes,
        union: submitForm.union,
        country: submitForm.country,
        vendorType: submitForm.vendorType,
        federalCertification: submitForm.federalCertification,
        trades: selectedTrades,
        heldLicenses: submitForm.heldLicenses,
        selectedState: getState().subForm.selectedState,

        yearsInBusiness: submitForm.yearsInBusiness,
        numberOfEmployees: submitForm.numberOfEmployees,
        modRate3Years: submitForm.modRate3Years,
        hasSafetyPolicy: submitForm.hasSafetyPolicy,
        vendorId: submitForm.vendorId,
        isSubAcknowledged: submitForm.isSubAcknowledged
      });

      console.log('Built JSON for formdata');

      f.append('json', json);

      //f.append("W9Form", w9, w9.name);
      if (submitForm.w9Form)
        f.append('w9Form', submitForm.w9Form, submitForm.w9Form.name);
      if (submitForm.sBEForm)
        f.append(`sBEForm`, submitForm.sBEForm, submitForm.sBEForm.name);
      if (submitForm.mBEForm)
        f.append(`mBEForm`, submitForm.mBEForm, submitForm.mBEForm.name);
      if (submitForm.wBEForm)
        f.append(`wBEForm`, submitForm.wBEForm, submitForm.wBEForm.name);
      if (submitForm.vBEForm)
        f.append(`vBEForm`, submitForm.vBEForm, submitForm.vBEForm.name);
      if (submitForm.uSBLNForm)
        f.append(`uSBLNForm`, submitForm.uSBLNForm, submitForm.uSBLNForm.name);
      if (submitForm.hZBEForm)
        f.append(`hZBEForm`, submitForm.hZBEForm, submitForm.hZBEForm.name);
      if (submitForm.dBEForm)
        f.append(`dBEForm`, submitForm.dBEForm, submitForm.dBEForm.name);
      if (submitForm.dVBEForm)
        f.append(`dVBEForm`, submitForm.dVBEForm, submitForm.dVBEForm.name);
      if (submitForm.lGBTBEForm)
        f.append(
          `lGBTBEForm`,
          submitForm.lGBTBEForm,
          submitForm.lGBTBEForm.name
        );

      console.log('Added W9 file to formdata');

      //if (coi.name && subagrmt.name) {
      //  f.append("SubAgreement", subagrmt, subagrmt.name);
      //  f.append("COI", coi, coi.name);
      //}

      console.log('Built formdata');

      let fetchTask = fetch(`api/SubForm/SaveForm?isUpdate=${isUpdate}`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          //'Accept': 'application/json, multipart/form-data, text/plain, */*',
          //'Content-Type': 'application/json'
        },
        body: f,
      })
        .then((res) => res.json() as Promise<boolean>)
        .then((data) => {
          dispatch({ type: 'SUBMITTED_NEWPAGE', success: !data });
          // refresh the page after submitting without any problems
          if (!data) {
          }
        })
        .catch((err) =>
          console.log(`Error came from submitForm actionCreator: ${err}`)
        );

      //addTask(fetchTask);
      dispatch({ type: 'FORM_SUBMITTED' });
    },

  modalButton: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const messageValue = getState().subForm.message;
    const successValue = getState().subForm.success;
    if (successValue) {
      dispatch({ type: 'SUBMITTED_NEWPAGE', success: false });
      location.reload();
    } else {
      if (messageValue) {
        dispatch({ type: 'MESSAGE', message: false });
      } else {
        dispatch({ type: 'MESSAGE', message: true });
      }
    }
  },

  getVendorForm:
    (id: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(`api/SubForm/GetForm?vendorId=${id}`, {
        credentials: 'same-origin',
      })
        .then((res) => res.json() as Promise<SubFormVM>)
        .then((data) => {
          dispatch({
            type: 'RECEIVE_FORM',
            form: data,
          });
        })
        .catch((err) => console.log(err));

      //addTask(fetchTask);
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: SubFormState = {
  trade: [],
  selectedTrade: [],
  unselectedTrade: [],
  message: false,
  selectedState: [],
  success: false,
  subForm: undefined,
};

export const reducer: Reducer<SubFormState> = (
  state: SubFormState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'RECEIVE_TRADELIST':
      return {
        ...state,
        trade: action.trade,
      };
    case 'RECEIVE_SELECTEDTRADE':
      return {
        ...state,
        selectedTrade: action.selectedTrade,
      };
    case 'RECEIVE_REMOVEDTRADE':
      return {
        ...state,
        trade: action.trade,
      };
    case 'FORM_SUBMITTED':
      return {
        ...state,
      };
    case 'MESSAGE':
      return {
        ...state,
        message: action.message,
      };
    case 'RECEIVE_SELECTED_STATE':
      return {
        ...state,
        selectedState: action.selectedState,
      };
    case 'SUBMITTED_NEWPAGE':
      return {
        ...state,
        success: action.success,
      };
    case 'RECEIVE_FORM':
      return {
        ...state,
        subForm: action.form,
        selectedTrade: action.form.trades,
        selectedState: action.form.selectedState,
      };
    default:
    // The following line guarantees that every action in the KnownAction union has been covered by a case above
    //const exhaustiveCheck: never = action;
  }

  // For unrecognized actions (or in cases where actions have no effect), must return the existing state
  //  (or default initial state if none was supplied)
  return state || unloadedState;
};
